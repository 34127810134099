.MuiAppBar-root {
	&.header-appbar {
		background-color: #403a3a;
	}

	.header-logo {
		max-height: 3.75rem;
	}

	.header-menu-wrap {
		justify-content: space-between;
	}

	.header-menu-button {
		color: #fff;
	}
}

.header-spacer-toolbar {
	padding-bottom: 10px;
}

.version.MuiTypography-body1 {
	color: #ddd;
	font-size: 0.9rem;
	padding: 0.375rem 1rem;
	text-align: right;
	user-select: none;
}
