@import '../node_modules/react-colorful/dist/index.css';
@import '../node_modules/react-toastify/dist/ReactToastify.css';

html {
	font-size: 16px;
	background-color: #161d1d;
}

html,
body,
#root {
	height: fit-content;
	min-height: 100%;
	-ms-overflow-style: none; /* Internet Explorer 10+ */
	background-color: #161d1d;
	scrollbar-width: none; /* Firefox */

	&::-webkit-scrollbar {
		display: none; /* Safari and Chrome */
	}
}

::-moz-focus-inner {
	padding: 0;
	border-style: none;
}

.react-colorful__saturation {
	border-radius: 0;
}

.react-colorful__hue {
	display: none;
}

.react-colorful__alpha {
	display: none;
}

.MuiTypography-root {
	margin-top: 0.5rem !important;
}

.MuiOutlinedInput-notchedOutline {
	border-color: rgba(255, 255, 255, 0.24) !important;
}

.Mui-error {
	.MuiOutlinedInput-notchedOutline {
		border-color: rgba(255, 0, 0, 0.24) !important;
	}
}

.sr-only {
	border: 0;
	clip: rect(0, 0, 0, 0);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute;
	white-space: nowrap;
	width: 1px;
}

.contrast-container {
	background-color: #161d1d;
	height: calc(100vh - 75px);
}

.custom-color-picker {
	width: 100%;
	height: 16rem;
}

.picker-form {
	width: 96%;
	padding: 0.5rem;
	margin: 0.5rem auto 0.5rem auto;
	box-sizing: border-box;
}

.MuiInputBase-input {
	text-transform: uppercase;
}

.full-page {
	align-items: center;
	display: flex;
	height: calc(100vh - 75px);
	justify-content: center;
	width: 100%;
}
